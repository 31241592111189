.table-of-contents {
    background-color: white;
    // border: 0.1px solid #dee2e6;
    display: block;
}

@media (max-width: 767px) {
    .table-of-contents-horizontal,
    .table-of-contents {
        display: flex;
        overflow: scroll;
        flex-wrap: nowrap;
        white-space: nowrap;
    }
}

.table-of-contents-horizontal {
    display: flex;
    overflow: scroll;
    flex-wrap: nowrap;
    white-space: nowrap;
    // border-bottom: 0.5px solid #dee2e6;
    // position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 5rem;
        background-image: linear-gradient(
            to left,
            rgba(255, 255, 255, 1),
            rgba(255, 255, 255, 0)
        );
        // transition: all linear 0.3s;
    }
    // &::before {
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     height: 100%;
    //     width: 5rem;
    //     background-image: linear-gradient(
    //         to right,
    //         rgba(255, 255, 255, 1),
    //         rgba(255, 255, 255, 0)
    //     );
    //     transition: all linear 0.3s;
    // }
}

.table-of-contents-horizontal .nav-link:first-of-type {
    // padding-left: 0;
}

.table-of-contents-horizontal .nav-link {
    padding-top: 1rem;
}
