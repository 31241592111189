.box {
    @extend .card;
    @extend .card-body;
    margin-bottom: $gap;
    &:last-of-type {
        margin-bottom: 0;
    }

    p:last-of-type {
        margin-bottom: 0;
    }

    dl:last-of-type {
        dd {
            margin-bottom: 0;
        }
    }
}