@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 100% 100%;
    --background-neutral: 208 4% 95%;
    --background-neutral-light: 22 10% 97%; /* #f8f9f6 */
    --foreground: 208 4% 10%;
    --card: 208 4% 90%;
    --card-foreground: 208 4% 15%;
    --popover: 208 4% 95%;
    --popover-foreground: 208 95% 10%;
    --primary: 208 56.4% 45.9%;
    --primary-foreground: 0 0% 100%;
    --secondary: 198 70.2% 50%;
    --secondary-foreground: 0 0% 100%;
    --muted: 170 10% 85%;
    --muted-foreground: 208 4% 35%;
    --accent: 170 10% 80%;
    --accent-foreground: 208 4% 15%;
    --destructive: 0 50% 30%;
    --destructive-foreground: 208 4% 90%;
    --border: 208 20% 50%;
    --input: 208 20% 30%;
    --ring: 208 56.4% 45.9%;
    --user: 0 0% 29%;
    --radius: 0.5rem;
  }
  .dark {
    --background: 208 10% 10%;
    --foreground: 208 4% 90%;
    --card: 208 4% 10%;
    --card-foreground: 208 4% 90%;
    --popover: 208 10% 5%;
    --popover-foreground: 208 4% 90%;
    --primary: 208 56.4% 45.9%;
    --primary-foreground: 0 0% 100%;
    --secondary: 208 10% 20%;
    --secondary-foreground: 0 0% 100%;
    --muted: 170 10% 25%;
    --muted-foreground: 208 4% 60%;
    --accent: 170 10% 25%;
    --accent-foreground: 208 4% 90%;
    --destructive: 0 50% 30%;
    --destructive-foreground: 208 4% 90%;
    --border: 208 20% 30%;
    --input: 208 20% 30%;
    --ring: 208 56.4% 45.9%;
    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply tw-border-border;
  }
  body {
    @apply tw-bg-background tw-text-foreground;
  }
}


@layer utilities {
  .scroll-mask {
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 1.0) 0%, transparent 100%);
  }
  .gradient-mask {
    mask: linear-gradient(to bottom,black, transparent);
    backdrop-filter: blur(6px);
  }
  .gradient-mask-from-left {
    /* mask-image: linear-gradient(to left, rgba(0, 0, 0, 1.0) 10%, transparent 80%); */
    /* mask: linear-gradient(to left, black 10%, transparent 80%); */
    /* filter: blur(18px); */
    backdrop-filter: blur(10px);
  }
}

.text-shadow {
  text-shadow: 0.3px 0.3px 0px #000;
}

a {
  color:  hsl(var(--primary))
}
