.accordion {

    .accordion-group {

        padding: 10px 0;

        &:first-child {
            padding-top: 0;
        }

        .accordion-header {
            font-weight: bold;
        }

    }

}