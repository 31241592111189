.content-box {
    display: block;
    position: relative;
    margin-bottom: 30px;

    .content-box-content {
        padding: 1rem;
        height: 320px;
        color: $white;
        -webkit-font-smoothing: antialiased;
        background-color: rgba(0, 0, 0, 0.6);
    }

    &:hover {
        text-decoration: none;
        .content-box-content {
            background-color: rgba(0, 0, 0, 0.8);
        }
    }

    .content-box-category {
        color: #c0c0c0;
        font-size: 0.9rem;
    }

    .content-box-title {
        font-weight: bold;
        margin: 0.4rem 0;
    }

    .content-box-date {
        color: #c0c0c0;
        font-size: 0.9rem;
    }

    .article-box-img {
        position: absolute;
        bottom: 0;
        max-width: 100%;
        max-height: 100%;
        z-index: -1;
    }

    .content-box-image-cover {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .content-box-image-bottom-cover {
        position: absolute;
        bottom: 0;
        max-width: 100%;
        max-height: 100%;
        z-index: -1;
    }

    .content-box-image-bottom,
    .grant-box-img {
        position: absolute;
        bottom: 7.5%;
        max-width: 85%;
        max-height: 30%;
        left: 7.5%;
        right: 7.5%;
        margin: auto;
        z-index: -1;
    }

    .knowledge-box-img {
        position: absolute;
        max-width: 100%;
        max-height: 100%;
        bottom: 0%;
        top: 0%;
        right: 0%;
        left: 0%;
        margin: auto;
        z-index: -1;
    }
}
