#profile-info {
    dl {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        dt {
            font-weight: bold;
        }
    }
}


.disabled-fields {
    position: relative;

    input {
        padding-right: 9.0rem!important;
        text-overflow: ellipsis;
    }

    .btn {
        position: absolute;
        top: 3px;
        right: 0;
        font-size: 0.8rem;
    }
}
