// Override bootstrap variables
$font-family-sans-serif: "Open Sans", sans-serif;

$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #d6d6d6;
$gray-500: #969696;
$gray-600: #575757;
$gray-700: #4a4a4a;
$gray-800: #333333;
$gray-900: #212529;
$black: #000;
$white: #ffffff;
$input-placeholder-color: $gray-400;
$navbar-light-color: rgba($gray-100, 0.5);
$navbar-light-toggler-border-color: rgba($gray-100, 0.5);

$primary: #337ab7;
$secondary: #26a4d9;

$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;
$break-small: 320px;

$body-color: $black;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
    xs: 0,
    sm: 640px,
    md: 768px,
    lg: 1024px,
    xl: 1280px,
    xxl: 1440px
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 992px,
    xl: 1200px,
    // xxl: 1336px,
        xxl: calc(83%)
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

// $grid-columns: 12 !default;
// $grid-gutter-width: 30px !default;
// $grid-row-columns: 6 !default;

// Buttons & Badges

$border-radius: 2px;
.badge {
    margin: 0 0.25rem 0 0;
}

// tabs

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border-width: 2px;
    border-left-width: 0;
    border-top-width: 0;
    border-right-width: 0;
}
