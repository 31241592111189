.toc-choices,
.toc-upload-documents {
    background-color: #fbfbfb;
    border: 2px dashed rgba(10, 10, 10, 0.2);;
    padding: 10px;
}

.toc-choices {
    .toc-choice {
        padding-left: 10px;
        margin-bottom: 1.5rem;
        &:last-of-type {
            margin-bottom: 0;
        }
        ol {
            list-style: upper-roman;
            padding-left: 5rem;
        }
        .key {
            padding-left: 15px;
            padding-right: 15px;
        }
        label {
            display: block;
            cursor: pointer;
            margin-bottom: 0;
        }
    }
}

.toc-spacer {
    display: inline;
    margin-left: 20px;
    &:first-of-type {
        margin-left: 40px;
    }
}