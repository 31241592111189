@import "settings/profile";

.nav-tabs {
    &--vertical {
        border-bottom: none;
        border-right: 1px solid #dee2e6;
        display: flex;
        flex-flow: column nowrap;
    }

    &--left {
        .nav-link {
            transition: border-color .125s ease-in;
            white-space: nowrap;

            &:hover {
                border-color: #dee2e6;
                margin-right: -1px;
            }
        }
        .nav-link.active {
            border-bottom-color: #dee2e6;
            border-right-color: #fff;
            margin-right: -1px;
        }
    }
}