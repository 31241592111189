/**
 * Login Page
 */

#login-container {
    max-width: 400px;
    padding: 60px 0;
}

.login-links {
    margin: 10px;
    line-height: 1.5rem;
    font-size: 0.9rem;
    color: white;
    text-align: center;
    a {
        color: white;
    }
}

.login-background {
    background-color: #585858;
    background-image: url("/static/cover-images/field.jpg");
    background-size: cover;
}
