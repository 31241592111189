/**
 * Modifiers
 */
.text-pending-approval,
.text-draft {
    color: $warning;
}
.text-active {
    color: $success;
}
.text-declined {
    color: $danger;
}
.text-closed {
    color: $gray-600;
}

.section-title {
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 10px;
}

.badge-pending-approval,
.badge-draft {
    color: white;
    background-color: $warning;
}
.badge-active {
    color: white;
    background-color: $success;
}
.badge-declined {
    color: white;
    background-color: $danger;
}
.badge-closed {
    color: black;
    background-color: $gray-600;
}

.is-large-social {
    &.icon {
        font-size: 1.5rem;
    }
}

.is-centered {
    margin: 0 auto;
}

.is-expanded {
    .button {
        width: 100%;
    }
}

.text-disabled {
    color: $input-disabled-bg !important;
}

.flex-1 {
    flex: 1;
}

ul.list-style-circle {
    list-style: circle !important;
}
ul.list-style-square {
    list-style: square !important;
}
ol.list-style-upper-roman {
    list-style: upper-roman !important;
}
ol.list-style-lower-alpha {
    list-style: lower-alpha !important;
}

.img-thumbnail {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 3px 0px,
        rgba(0, 0, 0, 0.05) 0px 1px 1px 0px,
        rgba(0, 0, 0, 0.05) 0px 2px 1px -1px;
    -webkit-font-smoothing: antialiased;
    background: white;
    border-width: 0;
    border-style: solid;
    border-color: rgb(222, 226, 230);
    border-image: initial;
}

// articles

.article-box-image-bottom-cover {
    transition: transform 0.4s;
}
.article-box-container:hover .article-box-image-bottom-cover {
    transform: scale(1.05);
}
