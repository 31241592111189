/**
 * Login Page
 */

#signin-container {
    max-width: 600px;
    padding: 60px 0;

    .signup-success {
        margin-bottom: 0;

        p:last-of-type {
            margin-bottom: 0;
        }
    }
}

.signup-links {
    margin: 10px 0 0 0;
    line-height: 1.5rem;
    font-size: 0.9rem;
    text-align: center;
    color: white;
    a {
        color: white;
    }
    p:last-of-type {
        margin-bottom: 0;
    }
}

.signup-terms-and-privacy {
    margin-top: $gap;
    margin-bottom: 0;
    text-align: center;
    font-size: 0.8rem;
}

.signup-background {
    background-color: #585858;
    background-image: url("/static/cover-images/building.jpg");
    background-size: cover;
}
