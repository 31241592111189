.register-institution-menu {
    .icon {
        margin: 0 5px 0 -5px;
    }
}

.list-group-item {
    .icon {
        &.success {
            color: $success;
        }
    }
    &.active {
        .icon {
            &.success {
                color: $white;
            }
        }
    }

    &.one-institution-listing {
        border: 1px solid #e9ecef;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 5px -6px;
        &:hover {
            cursor: pointer;
            background-color: rgba(38, 164, 217, 0.19);
        }
    }
}
