.widgets {
    margin-top: 2rem;

    .card-deck {
        margin-bottom: 2rem;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.widget {
    .disabled {
        background-color: $gray-100;
    }

    .large-number {
        font-size: 4em;
        text-align: center;
        color: rgb(20, 88, 140);

        small {
            display: block;
            color: $text-muted;
        }
    }

    .chart-container {
        position: relative;
        display: block;
        overflow: scroll;
        max-height: 300px;
    }
}
