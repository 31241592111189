// Override Bootstrap defaults
@import "overrides";

// Bootstrap v4
@import "../../node_modules/bootstrap/scss/bootstrap";

// Theme variables
$gap: 20px;

$blue: $primary;
$info: $blue;

$grey-lighter: $gray-500;
$grey-light: $gray-600;
$grey-dark: $gray-700;
$menu-item-color: $grey-light;

$datepicker__border-radius: 0;
$datepicker__border-color: $gray-400;

// Custom components
@import "../../node_modules/react-datepicker/src/stylesheets/datepicker.scss";
@import "component/typography";
@import "component/accordion";
@import "component/box";
@import "component/datePicker";
@import "component/circle";
@import "component/content-box";
@import "component/table-of-contents";
@import "component/nprogress";
@import "component/deals";
@import "component/widget";
@import "modifiers";

/**
 * General
 */
html {
    -webkit-font-smoothing: antialiased;
    height: 100%;
    font-size: 18px;
    scroll-behavior: smooth;
}

@media (max-width: 767px) {
    html {
        font-size: 16px;
    }
}

.sticky {
    position: sticky;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    top: 0;
    z-index: 1;
}

.disable-sticky {
    top: auto !important;
    position: relative !important;
}

.is-content-container {
    padding-top: $gap;
    padding-bottom: $gap;

    > p:last-of-type {
        margin-bottom: 0;
    }

    .content {
        p:last-of-type {
            margin-bottom: 0;
        }
    }
}

.article-content {
    position: relative;
    img {
        max-width: 100%;
    }
    p {
        white-space: normal;
    }
    p:last-of-type {
        margin-bottom: 0;
    }
    h2 {
        font-size: 1.5rem;
    }
}

.is-title {
    padding: $gap 0;
    margin-bottom: 0;
    font-weight: normal;
    color: $white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fluid-background {
    background-color: $light;
}

.btn-grey {
    @extend .btn-primary;
}

.section-header {
    font-size: 1.8rem;
    font-weight: 500;
    text-transform: capitalize;
    margin: 1rem 0;
}

.card-columns {
    @include media-breakpoint-up(lg) {
        column-count: 4;
    }
}

.pac-container {
    z-index: $zindex-modal + 100;
}

.row-save-and-next {
    position: sticky !important;
    bottom: 0;
    background-color: $light;

    .last-saved-time {
        float: left;
        margin-left: 0;
    }
}

/**
 * Header
 */
.navbar {
    padding: 0;
    .primary-logo {
        overflow: hidden;
        padding: 0;
        height: 60px;

        img {
            height: 65px;
        }
    }

    .primary-nav {
        .nav-item {
            &.active {
                color: $light;
            }
        }
        .nav-link {
            color: $white;
            font-size: 15px;
            -webkit-font-smoothing: antialiased;
            &:focus {
                color: $white;
                background-color: lighten($secondary, 10);
            }
            &:hover {
                color: $white;
                background-color: lighten($secondary, 10);
            }
            &.active {
                color: $white;
                font-weight: bold;
            }
        }
    }

    .right-nav {
        .nav-link,
        .nav-text {
            font-size: 0.9rem;
        }

        .navbar-text {
            color: $white;
            font-weight: normal;
            font-size: 0.9rem;
            -webkit-font-smoothing: antialiased;
        }
    }
}

.btn-user {
    background-color: transparent;
    padding: 0;
    border: 0;
    font-size: 1.4rem;
    &:hover {
        color: darken($white, 20%);
        background-color: $grey-dark;
    }
    &:focus {
        border: 0;
        outline: none;
    }
    .caret-next-user {
        margin-left: 5px;
        font-size: 1rem;
    }
}

.btn-group:hover .user-box {
    display: block;
}

.navbar-container {
    background-color: $secondary;

    .navbar-toggler {
        border: 0;
    }

    &.dark {
        background-color: $grey-dark;

        .navbar {
            .primary-nav {
                .nav-item {
                    &.active {
                        color: $light;
                    }
                }
                .nav-link {
                    color: $white;
                    font-size: 15px;
                    -webkit-font-smoothing: antialiased;
                    &:focus {
                        color: darken($white, 20%);
                        background-color: lighten($grey-dark, 10);
                    }
                    &:hover {
                        color: darken($white, 20%);
                        background-color: lighten($grey-dark, 10);
                    }
                    &.active {
                        color: $white;
                        font-weight: bold;
                    }
                }
                .user-box {
                    font-size: 15px;
                }
            }
        }
    }
}
.icon-in-offer-section {
    max-height: 100px;
    max-width: 100px;
}

.icon-in-offer-section {
    @include media-breakpoint-down(sm) {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}
.number-on-blended-finance span {
    @include media-breakpoint-down(sm) {
        max-width: 100%;
        font-size: 80px;
    }
}
@include media-breakpoint-down(sm) {
    #funding-committee {
        margin-top: 20px;
    }
    .bio-info {
        text-align: center;
    }
    .bio {
        text-align: left;
        margin-right: 10px;
    }
}
.last-saved-time {
    color: $warning;

    margin-left: 1em;
}
.mobile-nav-container {
    display: none;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: $zindex-tooltip;

    @keyframes zoomin {
        from {
            opacity: 0;
            transform: scale(0);
        }

        to {
            opacity: 1;
            transform: scale(1);
        }
    }

    .mobile-nav {
        position: relative;
        display: block;
        margin: 10px;
        box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.05);
        background-color: $white;
        animation-duration: 0.3s;
        animation-name: zoomin;

        padding: 1rem;

        .btn-group {
            width: 100%;
            > .btn {
                width: 33%;
                &:first-child {
                    margin-right: 5px;
                }
            }
        }

        .close-button {
            position: relative;
            text-align: right;

            &:hover {
                color: $primary;
            }
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                margin: 0;
                padding: 0;

                .nav-link {
                    padding: 0.5rem 0;
                    font-size: 1.2rem;
                    transition: all 0.2s ease;

                    &.active {
                        font-weight: bold;
                    }

                    .icon {
                        display: none;
                        margin-right: 5px;
                    }

                    &:hover {
                        .icon {
                            display: inline-block;
                        }
                    }
                }
            }
        }

        .split-columns {
            li {
                width: 50%;
                display: inline-block;
                vertical-align: top;
            }
        }
    }
}

.top-nav {
    position: absolute;
    top: 0;
    right: 0;
}

.is-top-nav-link {
    color: $white;
    border: 0;

    text-transform: uppercase;

    border-radius: 0;

    background-color: #646464;

    &.is-active {
        color: $white;
        background-color: $primary;
    }

    &:hover {
        color: $white;
        background-color: $primary;
    }
}

.is-top-nav-addon {
    border-radius: 0;
}

.is-join-link {
    float: left;
    margin-right: 10px;
    color: $white;
    background-color: $secondary;
}

#top-nav-bar {
    position: absolute;
    right: 0;
    top: -10px;
}

#main-nav-bar {
    max-height: 50px;
    margin-top: $gap;
}

/**
 * TitleBar
 */
#primary-title-bar {
    background-color: $secondary;
    .columns {
        padding: 10px 0;
        color: $white;
    }
}

.is-title {
    font-size: 1.5rem;
    text-transform: uppercase;
}

/**
 * Modal
 */
.modal-footer,
.modal-header {
    background-color: $light;
    box-sizing: content-box;
}

.modal-title {
    color: $gray-800;
    font-weight: normal;
}

/**
 * Card
 */

.card-body {
    p:last-child {
        margin-bottom: 0;
    }
}

.dashboard {
    .card-columns {
        @include media-breakpoint-up(lg) {
            column-count: 3;
        }
    }
}

/**
 * Form
 */
.form-group {
    label {
        font-weight: bold;
    }

    > .form-check {
        margin-bottom: 0;
    }
}

.form-check {
    margin-bottom: 1rem;
}
/*
select.form-control {
    border: 0;
    outline: 1px solid $input-border-color;
    outline-offset:-1px
}
*/

/**
 * Pages
 */
@import "page/home-page";
@import "page/error";
@import "page/login";
@import "page/signup";
@import "page/settings";
@import "page/institution";

/**
 * Footer
 */

.is-page-footer {
    background-color: $gray-100;
    -webkit-font-smoothing: antialiased;

    .list-unstyled {
        color: $gray-500;
        a {
            color: $gray-500;
            &:hover {
                color: $secondary;
            }
        }

        li {
            margin: 10px;
        }
        font-size: 0.87em;
    }

    .footer-title {
        color: $gray-700;
        font-weight: bold;
    }

    .footer-icon {
        margin-right: 15px;
    }
}
.institution-logo {
    width: 100%;
}

.cookie-use-notification {
    position: fixed;
    bottom: 0;
    width: 100%;

    z-index: $zindex-tooltip;

    background-color: $gray-800;
    color: #ffffff;
    font-size: 15px;
    font-family: "Open Sans", sans-serif;
    padding-left: 20px;

    .btn {
        font-family: "Open Sans", sans-serif;
    }

    #learn-more-btn {
        background-color: $gray-600;
        border-color: $gray-600;
    }
}

.div-contain-institution-logo img {
    max-height: 5em !important;
    max-width: 5em !important;
    width: auto;
}
.div-contain-institution-logo {
    height: 5em !important;
    width: 5em !important;
    border: 0;
    border-radius: 50%;
    background-color: white;
    box-shadow: inset 0px 0px 4px 2px rgba(0, 0, 0, 0.03);
}

.one-institution-listing {
    padding: 10px;

    cursor: pointer;

    margin-bottom: 10px;
}

.institution-overview {
    margin-left: 20px;
    margin-top: 6px;
    h5 {
        text-transform: uppercase;
    }
    p {
        font-size: 15px;
        margin-bottom: 0;
    }
}

.member-detail-profile-pic {
    border: 0;
    padding: 0;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}
.card-body {
    .sticky-date-and-save-btn {
        position: sticky;
        bottom: 0;
        background-color: white;
    }
}

.view-all-events-btn {
    background: #25a4d8;
    color: white;

    @include media-breakpoint-up(md) {
        margin-top: 10px;
    }
}

// Tailwind reset overrides

.btn-primary {
    background-color: $primary !important;
}
.btn-primary:hover {
    background-color: #2b6699 !important;
}
.btn-secondary {
    background-color: $secondary !important;
}
#content p {
    margin-bottom: 1rem !important;
}
ol {
    margin-bottom: 1rem !important;
    list-style: decimal !important;
}
