.select-members {
    display: flex;
    text-align: center;
}

.select-members > p {
    align-items: center;
    margin-bottom: 0;
}

.select-members img {
    display: inline;
    max-width: 115px;
    max-height: 85px;
    margin: 20px 50px 20px 0px;
}
