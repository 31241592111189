.table-users,
.table-deals {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;

    thead {
        th {
            padding: 0.5rem;
            border-bottom: 1px solid rgb(232, 232, 232);
        }
    }

    tbody {
        tr {
            margin-bottom: 10px;
            box-shadow: 0 4px 5px -6px rgba(0, 0, 0, 0.15);
        }

        td {
            padding: 0.5rem;
            border: 1px solid rgb(232, 232, 232);
        }

        td:first-child {
            border-right-width: 0;
        }

        td + td {
            border-left-width: 0;
            border-right-width: 0;
        }

        td:last-child {
            border-right-width: 1px;
        }
    }
}
