/* DayPicker styles */

.date-container {
    display: inline-block;
    padding: 0;
    width: auto;

    &.is-invalid {
        border: 1px solid $danger;
    }
}
