h1,
h2,
h3,
h4,
h5 {
    &.title {
        margin-bottom: 0;
    }
}
// h3,
// h4,
// h5 {
//     text-transform: lowercase;
// }
h3::first-line,
h4::first-line,
h5::first-line {
    text-transform: capitalize;
}
